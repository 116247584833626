import "./App.css";
import "./assets/iconfont/iconfont.css";
import MinderPage from "./Minder";

const App = () => {
  return (
    <div className="App">
      <MinderPage></MinderPage>
    </div>
  );
};

export default App;
